import React, { useState, useEffect } from "react";

const CookieConsent = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [preferencesClicked, setPreferencesClicked] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted === "true") {
      setAcceptedCookies(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setAcceptedCookies(true);
  };

  const declineCookies = () => {
    localStorage.setItem("cookiesAccepted", "false");
    setAcceptedCookies(false);
  };

  const handlePreferencesClick = () => {
    setPreferencesClicked(true);
  };

  if (acceptedCookies || preferencesClicked) {
    return null; // Don't render the banner if cookies have been accepted or preferences clicked
  }

  return (
    <div className="cookie-banner">
      <div className="container">
        <h1>Cookies Settings</h1>
        <p>
          We use cookies and similar technologies to help personalize content,
          tailor and measure ads, and provide a better experience. By clicking
          accept, you agree to this, as outlined in our Cookie Policy.
        </p>
        <div className="cookie-actions">
          <button onClick={acceptCookies} className="cookie-btn">
            Accept
          </button>
          <button onClick={handlePreferencesClick} className="cookie-btn">
            Preferences
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
