import React, { useEffect, useState } from 'react'
import imga from '.././assets/dream-logo-donate.png';
import { useLanguage } from "./LanguageContext";
import barcode from '.././assets/paypal_scanner.png';
import download_whtie from '.././assets/download_white-bg.jpg';
import { FaHeart } from 'react-icons/fa';
import { AiFillRightCircle } from "react-icons/ai";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import paypal from '../assets/paypal_qr.png'
const Donate = () => {
  const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();
  const { apiUrl , apiImg  } = useLanguage();
  const [data, setData] = useState('')
  const lan=useSelector((state)=>state?.app?.language);



  useEffect(() => {
    const fetchData = async () => {
    
      try {
        const response = await axios.post(`${apiUrl}donate_and_support`, {
          language: `${lan}`
        });
        
        const responseData = response.data.msg;
        setData(responseData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [lan]) 

  // const imgURL = `${apiImg}${data.images[0]}`
   const img = data?.images?.map((img)=>`${apiImg}${img}`)


  return (
  <div className='donate-bg-section'>

    <div className='container text-center py-5'>
      <div className=''>
      <h1>{data.heading}</h1>
      {/* <p className='my-2'>{data.donate_head_desc}</p> */}
      <div dangerouslySetInnerHTML={{ __html: data.description }} style={{}} />

      </div>
      <div className=''>
      <div className='my-5 container'>
        <div className="row">
        <div className="container col-md-12 col-lg-6 donate-bg" style={{ background: img && img.length > 0 ? `url(${img[0]})` : '' }}>
          {/* <div className="container col-md-12 col-lg-6 donate-bg" style={{background:`url(${img[0]})`:""` }} > */}
              <div className='row'>
                <div className='d-flex justify-content-between align-content-center'>
               <img src={imga} alt="Dream's logo"  height={50}/>

              <p className='justify-content-end'>@dreasmapp2nite</p>
                </div>
                 <div className="p-0 m-0 col-12 d-flex justify-content-center align-content-center">
                  <p className='donate-logo-head'>Dream's </p></div>
              </div>
              <div className='scanner'>
              {/*   <div><img src={`${img[1]}:""`} alt="paypal barcode" height={160.5} /></div>
                <div className='m-2'><img src={`${img[2]}:""`} alt="downloadicon"  height={95.5} /></div> */}
                {img && img.length > 1 && (
                             <div><img src={`${img[2]}`} alt="paypal barcode" height={150.5} /></div>
                            //  <div><img src={paypal} alt="paypal barcode" height={155.5} /></div>
                     )}
                  {img && img.length > 2 && (
                 <div className='m-2'><img src={`${img[1]}`} alt="downloadicon"  height={93.5} /></div>
                 )}
           
              </div>
          </div>
          <div className="ps-5 my-5 col-md-12 col-lg-6 d-flex justify-align-content-start flex-column">
          {/* <div dangerouslySetInnerHTML={{ __html: data.description2 }} style={{}} /> */}
<p>Help Us, Thanks to our free application, to develop these values throughout the World!</p>
          <div className='continar list_donate'>
            

            <div className='p-3 mt-3'>
            <h3>{data.heading2}</h3>
          <ul className=''>
            {data?.we_believe_list?.map((list, index)=>(
              <li key={index}><span className='list-icons'> <AiFillRightCircle /></span>{list}</li>

            ))}
            
             
          </ul>
              
             <Link to="https://www.paypal.com/donate/?hosted_button_id=ND6WSHM3BW74A" target='_blank'> <button className='my-1 support-btn'>{data.button_text}<span> <FaHeart /></span></button></Link> 
              
            </div>
          {/*   <p className='mt-3 mx-3'></p> */}
          {/* <h3>Share the Love <br/> DONATE</h3> */}
         
          
          </div>
          </div>
        </div>

      </div>
      </div>
      
    </div>
  </div>
  )
}

export default Donate
