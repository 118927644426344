import React from 'react'
import bgImage from '../../assets/blog-img.png';
import Blog_ScreenShot from './Blog_ScreenShot'
const Blog = () => {
  return (

    <div>

    <div style={{ background: `linear-gradient(#788ab37e, #963db981), url(${bgImage})` }} className="img-fluid position-relative overflow-hidden background-aboutus">

    <div className="container" >
        <div className="row">
            <div className="col-12">
                <h1 className='fs5-3 white padding_about text-center'>Dream's  Blogs</h1>
            </div>
        </div>
    </div>

</div>
<div>
    <Blog_ScreenShot/>
</div>
    </div>
  )
}

export default Blog
