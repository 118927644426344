import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from '../LanguageContext';
import img from '../../assets/about-home.jpg'
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

const AboutUs = () => {

  const { apiUrl ,apiImg  } = useLanguage();
  const [data, setData] = useState('')

  const lan=useSelector((state)=>state?.app?.language);
  useEffect(() => {
    AOS.init();

  }, []);


  useEffect(() => {
    const fetchData = async () => {
    
      try {
        const response = await axios.post(`${apiUrl}about_us`, {
          language: `${lan}`
        });

        const responseData = response.data.msg;
        setData(responseData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [lan]) 
  


 




const imgURL = `${apiImg}${data.image1}`;

  return (
    <div>
      {/* <div dangerouslySetInnerHTML={{ __html: data.description1 }} style={{}} /> */}
      <div style={{background:`linear-gradient(#788ab37e, #963db981), url(${imgURL})` }}  className="img-fluid position-relative overflow-hidden background-aboutus">

        <div className="container" >
          <div className="row">
            <div className="col-12">
              <h1 className='fs5-3 white padding_about text-center'>{data.heading}</h1>
            </div>
          </div>
        </div>
       
      </div>
      <div className='about-content'>

      <div className=' container py-5 overflow-hidden'>
        <div className='row my-3'>
          <div className='col-lg-6 col-md-12 mt-lg-5' data-aos="fade-right">
        

    <div dangerouslySetInnerHTML={{ __html: data.description1 }} style={{}} />
      
          </div>
          <div data-aos="fade-left" className='p-4 col-lg-6 col-md-12 d-flex justify-content-center  mx-auto align-items-center'>
            <img src={`${apiImg}${data.image2}`} alt='' className="about-content_img img-fluid" height={350}/>

          </div>
        </div>
       
        <div data-aos="fade-right" >

        <div dangerouslySetInnerHTML={{ __html: data.description2 }} style={{}} />

        </div>
     
      </div>
      </div>

    </div>
  )
}

export default AboutUs

