import React, { useEffect, useState } from 'react'
import { useLanguage } from "../LanguageContext";
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import axios from 'axios';


const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false)
  const { apiContact } = useLanguage();
  const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();
  const { apiUrl , apiImg  } = useLanguage();
  const lan=useSelector((state)=>state?.app?.language);
  const [data, setData] = useState('')


 
/* useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(`${apiUrl}newsletter_sections`, {
            language: `${lan}`
          });
  
          const responseData = response.data.msg;
          setData(responseData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [lan]) 

   

 */



  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
     // Validate email
     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
     if (!emailPattern.test(inputValue)) {
         setEmailError('Email is Required');
     } else {
         setEmailError('');
     }
  }


const handleSubmit = (e) => {

  e.preventDefault();

  if(email && !emailError)
  {
    setLoading(true);
    setEmail('')

    fetch(`${apiContact}send-mail-dreams-newslatter.php`, {
      method: 'POST',
  
      body: JSON.stringify({          
          email: email         
      }),
  }).then(response => {
    
     setLoading(false)
    if (response.ok) {
        // Handle successful response
        return response.json();
    }
    throw new Error('Network response was not ok.');
}).then(data => {
  // Handle data if needed
  Swal.fire({
      text: 'Submitted Successfully',
      icon: 'success',
      confirmButtonText: 'OK',
      confirmButtonColor: '#1e2025',
  }).then((result) => {
      if (result.isConfirmed) {
          window.location.reload();
      }
  });
})  .catch(error => {
  setLoading(false)
  // Handle error
  console.error('There was a problem with the fetch operation:', error);
});

}
else {
  if(!email){
    console.log('emailisNotProvide')
  }
}
}   

  return (
    <div className='newletter_bg'>

    <div className='container py-5'>
        <div className="newlatter d-flex justify-content-evenly align-content-center flex-wrap">
              <div><h3>Subscribe Our Newslatters for letest Updates</h3></div>
              <div className='input-news' >
                <form onSubmit={handleSubmit} className="news-form">
                <input type="email" placeholder='Enter Your Email ' className='newletter_input py-1 px-2 rounded-s-1' onChange={handleEmailChange}/>
                <button className='outline_none newstter-btn' >Submit</button>

                </form>
              </div>
        </div>
      
    </div>
    </div>
  )
}

export default Newsletter;
