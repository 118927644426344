import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLanguage } from "../LanguageContext";
import img1 from '../../assets/blog-1.jpg'
import img2 from '../../assets/blog-2.jpg'
import img3 from '../../assets/blog-3.jpg'
import img4 from '../../assets/blog-4.jpg'
import img5 from '../../assets/blog-5.jpg'
import img6 from '../../assets/blog-6.jpg'

import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import axios from 'axios';

import { useSelector } from 'react-redux';

const Compatibility_Test = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    useEffect(() => {
        AOS.init();
    }, []);


    const { apiUrl, apiImg } = useLanguage();
    const [data, setData] = useState('')

    const lan = useSelector((state) => state?.app?.language);



    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.post(`${apiUrl}compatibility_test`, {
                    language: `${lan}`
                });
              
                const responseData = response.data.msg;
                setData(responseData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [lan])






    return (
        <div className='overflow-hidden py-5' id='#section02'>
            <div className="container py-4" >
                <div className='row align-items-center overflow-hidden'>
                    <div className='col-lg-6 col-md-12 mb-4 mb-lg-0 overflow-hidden'>
                        <div data-aos="fade-left" className='ps-lg-5'>
                            <h2 className="mb-3 strem-heading" >Dream's Blogs</h2>
                            <p className='strem-pare'>In a World of Dreams and safe spaces, here is one where thoughts can flow freely. About Travel and Discoveries, Politics, Culture, Kitchen, Ideas, Dreams, Life Goals. Good days and Bad days.</p>
                            <h5 className='strem-heading-2'>We will strive to offer a blog according to Dream's Culture:</h5>
                            <ul>
                                <li>Respect</li>
                                <li>Empathy</li>
                                <li>Love</li>
                                <li>Principle and values.</li>
                            </ul>
                            <p className='strem-heading-2'>– Follow the Dreams Blog straight to the center of it all.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className='col-lg-6 col-md-12 mb-4 mb-lg-0'>

                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            onAutoplayTimeLeft={onAutoplayTimeLeft}
                            className="mySwiper bg_img_welcome"
                        >
                          {/*   {data?.images?.map((imageUrl, index) => (
                                <SwiperSlide key={data.id}>
                                    <div className=' py-3 p-3 ' >
                                        <img src={`${apiImg}${imageUrl}`} className='write_dream-img' alt={`Image ${index}`} />
                                    </div>
                                </SwiperSlide>
                            ))} */}
              
                                <SwiperSlide >
                                    <div className=' py-3 p-3 ' >
                                        <img src={img1} className='write_dream-img' alt='Dream social blag' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className=' py-3 p-3 ' >
                                        <img src={img2} className='write_dream-img' alt='Dream social blag' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className=' py-3 p-3 ' >
                                        <img src={img3} className='write_dream-img' alt='Dream social blag' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className=' py-3 p-3 ' >
                                        <img src={img4} className='write_dream-img' alt='Dream social blag' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className=' py-3 p-3 ' >
                                        <img src={img5} className='write_dream-img' alt='Dream social blag' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className=' py-3 p-3 ' >
                                        <img src={img6} className='write_dream-img' alt='Dream social blag' />
                                    </div>
                                </SwiperSlide>
                            
                            <div className="autoplay-progress d-none" slot="container-end">
                                <svg viewBox="0 0 48 48" ref={progressCircle}>
                                    <circle cx="24" cy="24" r="20"></circle>
                                </svg>
                                <span ref={progressContent}></span>
                            </div>
                        </Swiper>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default Compatibility_Test