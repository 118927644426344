import React, { useEffect, useRef, useState } from "react";
// import logo from '../assets/omni-telecom-log.png'
import logo from "../assets/logo-dreams.png";
import logo2 from "../assets/logo-dreams2.png";
import apple from "../assets/apple-download.png";
import android from "../assets/android-download.png";
import { NavLink } from "react-router-dom";
import { FaReddit } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { SiRumble } from "react-icons/si";
import { FaTiktok } from "react-icons/fa6";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLanguage } from "./LanguageContext";
/* import qr_apple from '../assets/apple_qr_code.png'
import qr_android from '../assets/android_qr_code.png' */
import qr_apple from "../assets/apple.svg";
import qr_android from "../assets/android_bar.svg";
import qr from "../assets/dream-scan-new.jpg";
import { useSelector } from "react-redux";
import axios from "axios";

const Footer = () => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const { apiUrl, apiImg } = useLanguage();
  const lan = useSelector((state) => state?.app?.language);
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}footer_section`, {
          language: `${lan}`,
        });

        const responseData = response.data.msg;
        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [lan]);

  const toggleMenu = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };

  const searchOpen = () => {
    setOpen(true);
  };

  const searchClose = () => {
    setOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();
  return (
    <div>
      <div className="pg-footer">
        <div className="footer">
          <div className="container">
            <div className="d-md-flex justify-content-md-between mt-5">
              <div className="">
                <div className="">
                  <h3 className="">Scan Me</h3>
                  <img
                    src={qr}
                    alt=""
                    height={110}
                    width={130}
                    className="me-3"
                  />
                </div>
              </div>
              <div className="">
                <div className="">
                  <h3 className="">Download</h3>
                  <ul className="footer-list me-3">
                    <li className="li_footer">
                      <a
                        href="https://apps.apple.com/in/app/dreamsocial-and-live-streaming/id6456945052"
                        target="_blank"
                      >
                        {" "}
                        <img
                          className="apple-download"
                          src={apple}
                          alt="Company Logo"
                        />
                      </a>
                    </li>
                    <li className="li_footer">
                      {" "}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.shrinkcom.dreamsdating"
                        target="_blank"
                      >
                        {" "}
                        <img
                          className="android-download"
                          src={android}
                          alt="Company Logo"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="">
                <div>
                  <h3 className="">Our Company</h3>
                  <ul className="footer-list">
                    <li
                      onClick={() => {
                        handleClose();
                        scrollToTop();
                      }}
                    >
                      <Link
                        to="/aboutUs"
                        className="align-items-center d-flex gap-1 li_footer"
                      >
                        <MdOutlineKeyboardDoubleArrowRight className="arrow" />
                        About Us
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleClose();
                        scrollToTop();
                      }}
                    >
                      <Link
                        to="/submit-request"
                        className="align-items-center d-flex gap-1 li_footer"
                      >
                        <MdOutlineKeyboardDoubleArrowRight className="arrow" />
                        Dream's Help
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleClose();
                        scrollToTop();
                      }}
                    >
                      <Link
                        to="/mission"
                        className="align-items-center d-flex gap-1 li_footer"
                      >
                        <MdOutlineKeyboardDoubleArrowRight className="arrow" />
                        Mission
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleClose();
                        scrollToTop();
                      }}
                    >
                      <Link
                        to="/contactUs"
                        className="align-items-center d-flex gap-1 li_footer"
                      >
                        <MdOutlineKeyboardDoubleArrowRight className="arrow" />
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="">
                <div className="">
                  <h3 className="">Headquarters</h3>
                  <ul className="footer-list">
                    <li className="li_footer">Jgd's Global Trading</li>
                    <li className="li_footer">Pmb:301</li>
                    <li className="li_footer">1931 Cordova Road</li>
                    <li className="li_footer">FORT LAUDERDALE, FL 33316</li>
                  </ul>
                </div>
              </div>

              <div className="">
                <div>
                  <h3 className="">Legal</h3>
                  <ul className="footer-list">
                    <li
                      onClick={() => {
                        handleClose();
                        scrollToTop();
                      }}
                    >
                      <Link
                        to="/privacy-statement"
                        className="align-items-center d-flex gap-1 li_footer"
                      >
                        <MdOutlineKeyboardDoubleArrowRight className="arrow" />
                        Privacy Statement
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleClose();
                        scrollToTop();
                      }}
                    >
                      <Link
                        to="/submit-request"
                        className="align-items-center d-flex gap-1 li_footer"
                      >
                        <MdOutlineKeyboardDoubleArrowRight className="arrow" />
                        Submit a request
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleClose();
                        scrollToTop();
                      }}
                    >
                      <Link
                        to="/dreams-safety"
                        className="align-items-center d-flex gap-1 li_footer"
                      >
                        <MdOutlineKeyboardDoubleArrowRight className="arrow" />
                        Dreams Safety
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleClose();
                        scrollToTop();
                      }}
                    >
                      <Link
                        to="/cookie-policy"
                        className="align-items-center d-flex gap-1 li_footer"
                      >
                        <MdOutlineKeyboardDoubleArrowRight className="arrow" />
                        Cookie Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="copyright text-center py-2">
              <p className="copyright-text">
                2024 Dream's (©) Copyright, dream's logo are registered
                trademarks by jgd's Global Trading LLc. 2024. All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
