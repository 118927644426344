import React from 'react'

const StreamingCommunity = () => {
    return (
        <div>
            <div className='container my-5'>

             <div className='row'>
               

                    <div className='col-12 col-md-6'>
                        <div className='d-flex justify-content-center align-items-center flex-column mb-3'>

                    <h3 className='mb-2 text-left'>Dream’s Streaming Community Guidelines</h3>
            <p className='mb-2'>Please respect the following community guidelines to maintain an enjoyable environment:</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                    <ul>
                <li>Keep conversation respectful, without personal attacks.</li>
                <li>No pornography</li>
                <li>Keep out behavior that could be seen as trolling/spamming.</li>
                <li>Relax, be yourself, and enjoy.</li>
            </ul>
                    </div>
                
        
             </div>
             <p className='my-5 main-text-stream'>The Dreams application guarantees you to escape with these many features,
 no matter where you are, Dream and Share it, LIVE!!!!
</p>
            </div>
            
        </div>
    )
}

export default StreamingCommunity
