import React, { useEffect, useState } from 'react'
import { useLanguage } from "../LanguageContext";
import img1 from '../../assets/dream-social-1.jpg'
import img2 from '../../assets/dream-social-2.png'
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import { useSelector } from 'react-redux';


const DreamScail = () => {
  const { apiUrl ,apiImg } = useLanguage();

  const [data, setData] = useState('')

  useEffect(() => {
    AOS.init();
}, []);



const lan=useSelector((state)=>state?.app?.language);



useEffect(() => {
  const fetchData = async () => {
  
    try {
      const response = await axios.post(`${apiUrl}dream_social`, {
        language: `${lan}`
      });

      const responseData = response.data.msg;
      setData(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  fetchData();
}, [lan]) 


  return (
    <div className="container overflow-hidden">
      <div className="why-chose-heading">
        <h1 className="why-choose-head text-center">{data.heading}</h1>
      </div>
      <div className='why_choose_container overflow-hidden'>

        <div  data-aos="fade-right" className='why_choose_img'>
            <img src={`${apiImg}${data.image1}`} alt='Img1' />
        </div>
        <div className='why_choose_para' data-aos="fade-left">
            <div>
            <div dangerouslySetInnerHTML={{ __html: data.description1 }} style={{}} />
{/* 
             <p>
             {data.dream_social_para1}
             </p>
             <h6> {data.dream_social_head_samall}</h6>
             <p>{data.dream_social_para2}<a href="mailto:Dreamsapp2nite@gmail.com">{data.dream_social_link}</a></p> */}
            </div>
        </div>

      </div>
     
      <div className='why_choose_container'>

       
        <div  data-aos="fade-right" className='why_choose_para order-md-1 order-2'>
            <div>
 {/*  <h4>{data.dream_social_head2}</h4>
             <p>
             {data.dream_social_head2_para}

             </p> */}

             {/* add component */}

<div dangerouslySetInnerHTML={{ __html: data.description2 }} style={{}} />

            
            </div>
        </div>
        <div data-aos="fade-left" className='why_choose_img order-md-2 order-1'>
            <img src={`${apiImg}${data.image2}`} alt='Img1' />
        </div>

      </div>
 
      
    </div>
  );
}

export default DreamScail;
