import React, { useEffect } from 'react'
import LiveScreenShot from './LiveScreenShot'
import StreamingCommunity from './StreamingCommunity'
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
const LiveStreaming = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <div>
            <div style={{ background: `linear-gradient(#788ab37e, #963db981), url('https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')` }} className="img-fluid position-relative overflow-hidden background-aboutus">

                <div className="container" >
                    <div className="row">
                        <div className="col-12">
                            <h1 className='fs5-3 white padding_about text-center'>Dream's Live Streaming</h1>
                        </div>
                    </div>
                </div>

            </div>
               <div className='container py-5'>

            <div className='row py-5 overflow-hidden'>
                <div className='col-12 col-md-6 ' data-aos="fade-left">
                    <div className='d-flex justify-content-center align-content-center   w-100 h-100'>
                    <h2 className='d-flex justify-content-center align-items-center strem-heading pb-4'>What is Live Streaming?</h2>
                    </div>
                </div>
                <div className='col-12 col-md-6' data-aos="fade-right">
                    <p className='text-justify strem-pare'><b className=''>Live streaming</b> is a broadcasting practice that brings audio and video of real-time happenings to mobile viewers over the internet. Live streaming is becoming more and more popular in the professional world as it helps businesses and organizations to connect with their audience on a deeper level despite the barrier of physical location. Increased access to events is why live streaming continues to gain in popularity.</p>

                    <p className='strem-heading-2'>Dream’s Live streaming is a great option to use if you want to:</p>
                    <ul>
                        <li className=''>
                        Spread the knowledge on your brand, product, or class
                        </li>
                    </ul>
                </div>
            </div>
            <div className='row overflow-hidden'>
                <div className='col-12 col-md-6 order-2 order-md-1 ' data-aos="fade-left">
                    <p className='text-justify strem-pare'>Dream’s live streaming is an easy way for users to reach their community in real time. Whether streaming an event, teaching a class, hosting a workshop etc. Dream’s has tools that will help manage live streams and interact with viewers in real time. Users can live stream on Dream’s via mobile App. </p>
                </div>
                <div className='col-12 col-md-6 order-1 order-md-2 pb-4 '>
                <div className='d-flex justify-content-center align-content-center  w-100 h-100 ' data-aos="fade-right">
                    <h2 className='d-flex justify-content-center align-items-center strem-heading'>Dream’s live streaming </h2>
                    </div>
                  
                </div>
            </div>
               </div>
         <div>
            <LiveScreenShot/>
         </div>
                 
                 <div>
                    <StreamingCommunity/>
                 </div>
        </div>
    )
}

export default LiveStreaming
