import React from "react";
import AdsComponent from "../AdsComponent";
const Adds = () => {
  return (
    <div>
      <h1 className="text-center">Google Add</h1>
      <AdsComponent />
    </div>
  );
};

export default Adds;
