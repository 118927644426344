import React, { useEffect, useState } from 'react';
import { useLanguage } from '../LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img_21 from '../../assets/mission-1.webp'
import img_2 from '../../assets/mission-2.webp'
import img_3 from '../../assets/mission-3.jpg'
import { useSelector } from 'react-redux';
import axios from 'axios';
const Mission = () => {
  const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();
  const { apiUrl , apiImg  } = useLanguage();
  const lan=useSelector((state)=>state?.app?.language);
  const [data, setData] = useState('')


    useEffect(() => {
        AOS.init();
    }, []);
useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(`${apiUrl}our_mission`, {
            language: `${lan}`
          });
  
          const responseData = response?.data;
          setData(responseData);
         
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [lan]) 




  return (
    <div className='mission_bg overflow-hidden'>

    
    <div className=' container py-5'>
      <div className="row">
        <div className="col-lg-6">
          <div className="row" data-aos="fade-right">
            <div className="col-6 " data-aos="fade-right">
            <img src={`${apiImg}${data?.msg?.images[0]}`} alt="img" className='img-fluid large-img'/>
            </div>
            <div className="col-6" >
                   <div className="row gap-2">
                    <div className="col-lg-12 ps-0"><img src={`${apiImg}${data?.msg?.images[1]}`} alt="img " className='img-fluid small-img small-img-1' /> </div>
                    <div className="col-lg-12 ps-0"><img src={`${apiImg}${data?.msg?.images[2]}`} alt="img " className='img-fluid small-img small-img-2'/></div>
                   </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-left" className="col-lg-6 d-flex justify-content-center flex-column my-3 ps-md-5">
        {/* <h1 className=''>{data.mission_head}</h1>
        <p>{data.mission_para}</p>
        <ul>
          <li>{data.mission_list1} </li>
          <li>{data.mission_list2} </li>
          <li>{data.mission_list3}</li>
          <li>{data.mission_list4}</li>
        </ul> */}
             <div dangerouslySetInnerHTML={{ __html: data?.msg?.description }} style={{}} />
        </div>
      </div>
    </div>
    </div>
  )
}

export default Mission