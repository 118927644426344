/* import React, { useEffect } from "react";

const AdsComponent = (props) => {
  useEffect(() => {
    if (typeof window.adsbygoogle !== "undefined") {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <>
     
      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-9202342999217930"
        data-ad-slot="6787009126"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </>
  );
};

export default AdsComponent; */

import React, { useEffect } from "react";

const AdsComponent = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-9202342999217930"
      data-ad-slot="6787009126"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default AdsComponent;
