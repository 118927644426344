import React, { useState, useEffect } from 'react';
import { RiBuilding4Line } from 'react-icons/ri';
import { MdOutlineEmail, MdOutlinePhoneInTalk } from 'react-icons/md';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from '../LanguageContext';
import { useSelector } from 'react-redux';
import axios from 'axios';



const ContactUs = () => {
    const { apiContact } = useLanguage();
    useEffect(() => {
        AOS.init();
    }, []);

    const [name, setName] = useState('');
    const [last_name, setLast_name] = useState('');
    const [phone, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [numberError, setNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { apiUrl , apiImg  } = useLanguage();
    const lan=useSelector((state)=>state?.app?.language);
    const [data, setData] = useState('')
  
 
  useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post(`${apiUrl}contactUs_form`, {
              language: `${lan}`
            });
    
            const responseData = response.data.msg;
            setData(responseData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, [lan]) 
  




    const handleNumberChange = (e) => {
        const inputValue = e.target.value;

        setNumber(inputValue);

        // Validate number
        if (isNaN(inputValue)) {
            setNumberError('Phone is Reqqired');
        } else {
            setNumberError('');
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);

        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(inputValue)) {
            setEmailError('Email is Required');
        } else {
            setEmailError('');
        }
    };
  const handleSubmit = (e) => {
        e.preventDefault();

        if (!numberError && !emailError && phone && email) {
            setLoading(true); // Show loader

            setNumber('');
            setEmail('');

            // Make HTTP POST request to the PHP file
            fetch(`${apiContact}send-mail-dreams-contactUs.php`, {
                method: 'POST',
            /*     headers: {
                    'Content-Type': 'application/json',
                }, */
                body: JSON.stringify({
                    first_name: name,
                    phone: phone,
                    email: email,
                    last_name: last_name,
                    message: message,
                    subject: "Contact Us"
                }),
            })
                .then(response => {
                    setLoading(false); // Hide loader

                    if (response.ok) {
                        // Handle successful response
                        return response.json();
                    }
                    throw new Error('Network response was not ok.');
                })
                .then(data => {
                    // Handle data if needed
                    Swal.fire({
                        text: 'Submitted Successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#1e2025',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                })
                .catch(error => {
                    setLoading(false); // Hide loader

                    // Handle error
                    console.error('There was a problem with the fetch operation:', error);
                });

        } else {
            if (!phone) {
                setNumberError('Phone is Reqqired');
            }
            if (!email) {
                setEmailError('Email is Required');
            }
        }
    };

    const handleNameChangeFirst = (event) => {
        setName(event.target.value);
    };
    const handleNameChangeLast = (event) => {
        setLast_name(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();

    return (

        <div>
            <div className="background-contactus overflow-hidden">
                <div className='container my-auto' >
                    <h1 className='fs5-3 text-white text-center mb-3' >Contact Us</h1>
                    <div className='contactus-form card' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                        <div className='card-body'>
                            {loading && (
                                <div className='position_loder'>
                                    <div className="spinner-border text-white" role="status">
                                        <span className="visually-hidden">{data.loading}</span>
                                    </div>
                                </div>
                            )}

                            <form onSubmit={handleSubmit}>

                                <div className='row' >
                                    <div class="col-md-6 mb-4">
                                        <label for="fullName" className="form-label">First Name</label>
                                        <input type="text" class="form-control" id="fullName" placeholder='First Name' value={name}
                                            onChange={handleNameChangeFirst}
                                        />
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <label for="busName" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id="busName" placeholder='Last Name' value={last_name}
                                            onChange={handleNameChangeLast} />
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label for="email" className="form-label">Email</label>
                                        <input type="email" id="email" placeholder='Email' className="form-control" value={email} onChange={handleEmailChange} />
                                        {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label for="" className="form-label">Phone</label>
                                        <input type="number" className="form-control" placeholder='Phone' value={phone} onChange={handleNumberChange} />
                                        {numberError && <div style={{ color: 'red' }}>{numberError}</div>}
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label for="message" className="form-label">Message</label>
                                        <textarea className="form-control" id="message" rows="3" placeholder='Message' value={message} onChange={handleMessageChange}></textarea>
                                    </div>
                                    <div className='col-12 text-center'>
                                        <button type="submit" className="btn btn-warning">Submit</button>
                                    </div>
                                    {/* <div className='col-12'>
                                        <div class="spinner-border text-dark" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div> */}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='container my-6 overflow-hidden' >

                    <div className='box-contact' >
                        <div className='width_contact mb-4' >
                            <div className='d-flex gap-3 align-items-center contactus-details' data-aos="fade-right" data-aos-easing="linear" data-aos-duration="600">
                                <div className='contact-icon-ring' >
                                    <RiBuilding4Line className='contact-icon black' />
                                </div>
                                <p className=' contact-detail text-center text-md-start' >PMB: 301
                                    <br />
                                    1931 Cordova Road
                                    <br />
                                    FORT LAUDERDALE, FL 33316</p>
                            </div>
                        </div>

                        <div className='width_contact mb-4' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                            <div className='d-flex gap-3 align-items-center contactus-details' >
                                <div className='contact-icon-ring' >
                                    <MdOutlineEmail className='contact-icon black' />
                                </div>
                                <p className=' contact-detail '> <Link to='mailto:kontakt@omnitelecom.se' className='text-dark'>dreamsapp2nite@gmail.com</Link> </p>
                            </div>
                        </div>
                        <div className='width_contact mb-4' >
                            <div className='d-flex gap-3 align-items-center contactus-details' data-aos="fade-left" data-aos-easing="linear" data-aos-duration="600">
                                <div className='contact-icon-ring' >
                                    <MdOutlinePhoneInTalk className='contact-icon black' />
                                </div>
                                <p className=' contact-detail' >+1 829 977 1029</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
