import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language:'',
};

export const counterSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLanguageData:(state,action)=>{
        state.language=action.payload;
    }
  },
});

export const { setLanguageData } = counterSlice.actions;

export default counterSlice.reducer;
