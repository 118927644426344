import React, { useEffect, useState } from "react";
import { useLanguage } from "../LanguageContext";
import img1 from "../../assets/why_choose_1.jpeg";
import img2 from "../../assets/why_choose_2.jpg";
import img3 from "../../assets/why_choose_3.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
const Main_Section = () => {
  const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();
  const { apiUrl, apiImg } = useLanguage();
  const [data, setData] = useState("");
  // const [cookies, setCookie] = useCookies(['selectedLanguage']);
  // const [cookiesLanguage, setCookiesLanguage] = useState(cookies.selectedLanguage);
  const lan = useSelector((state) => state?.app?.language);
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}why_choose_dreams`, {
          language: `${lan}`,
        });
        
        const responseData = response.data.msg;
        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [lan]);

  return (
    <div className="container overflow-hidden">
      <div className="why-chose-heading">
        <h1 className="why-choose-head text-center">{data.heading}</h1>
      </div>
      <div className="why_choose_container overflow-hidden">
        <div data-aos="fade-right" className="why_choose_img">
          <img src={`${apiImg}${data.image1}`} alt="Img1" />
        </div>
        <div className="why_choose_para" data-aos="fade-left">
          {/*    <p>
             {data.why_choose_dreams_para1}
             </p> */}
          <div
            dangerouslySetInnerHTML={{ __html: data.description1 }}
            style={{}}
          />
        </div>
      </div>
      <div className="why_choose_container">
        <div
          data-aos="fade-right"
          className="why_choose_para order-md-1 order-2"
        >
          {/*   <p>
             {data.why_choose_dreams_para2}
             </p> */}
          <div
            dangerouslySetInnerHTML={{ __html: data.description2 }}
            style={{}}
          />
        </div>
        <div data-aos="fade-left" className="why_choose_img order-md-2 order-1">
          <img src={`${apiImg}${data.image2}`} alt="Img1" />
        </div>
      </div>
      <div className="why_choose_container">
        <div data-aos="fade-right" className="why_choose_img">
          <img src={`${apiImg}${data.image3}`} alt="Img1" />
        </div>
        <div data-aos="fade-left" className="why_choose_para">
          {/*      <p>
     {data.why_choose_dreams_para3}
     </p> */}
          <div
            dangerouslySetInnerHTML={{ __html: data.description3 }}
            style={{}}
          />
        </div>
      </div>
    </div>
  );
};

export default Main_Section;
