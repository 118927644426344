import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { RiArrowDownDoubleFill } from 'react-icons/ri';
import { useLanguage } from '../LanguageContext'
import img3 from '../../assets/slide-3.jpg';
import img7 from '../../assets/slide-7.jpg';
import img8 from '../../assets/slide-8.jpg';
import img1 from '../../assets/slide-1.jpg';
import img9 from '../../assets/slide-9.jpg';
import img6 from '../../assets/slide-6.webp';
import img4 from '../../assets/slide-4.jpg';
import img10 from '../../assets/slide-10.jpg';

const BackgroundSlider = (imageArr) => {



  const { apiUrl , apiImg  } = useLanguage();
  const { language, changeLanguage } = useLanguage();
const { translations } = useLanguage();

  const images = imageArr.data.map((item) =>`${apiImg}${item.image}`);
  const textOverlays = imageArr.data.map((text) =>`${text.title}`);

 


/*   const textOverlays = [
    {
      
      title: `${translations.title_1}`,
    },
    {
      
      title: `${translations.title_2}`,
    },
    {
     
      title: `${translations.title_3}`,
    },
    {
      
      title: `${translations.title_4}`,
    },
    {

     title: `${translations.title_5}`,
    },
    {
      
      title: `${translations.title_6}`,
    },
    {
      title: `${translations.title_7}`,
      
    },
    {
      title: `${translations.title_10}`,
      
    },
    {
      // title: 'Nurture your vitality, achieve balance, with personalized care at your fingertips.',

    },
  ]; */

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // const [currentTextOverlay, setCurrentTextOverlay] = useState(textOverlays[0]);
  const intervalRef = useRef(null); // Ref to store interval ID

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const startSlideshow = () => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 4000); // Change slide every 5 seconds
  };

  const stopSlideshow = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    startSlideshow();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []); // Run only on mount and unmount

  const handleArrowClick = (direction) => {
    stopSlideshow(); // Pause slideshow when arrow is clicked
    direction === 'next' ? nextSlide() : prevSlide();
    startSlideshow(); // Resume slideshow after navigation
  };

  const handleScroll = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const offset = element.offsetTop - 20;
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };
// alert(images[currentImageIndex])

  return (

    <div className="background-slider">
      {
        imageArr.data.map((ele, index)=>(
          <div
          className="background-image" key={index}
          style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
        >
          <div className="container image-overlay-text">
            <h1>{textOverlays[currentImageIndex]}</h1>
          </div>
          <div className="slider-arrows">
            <button className="prev-arrow slider-btn" onClick={() => handleArrowClick('prev')}>
              <RiArrowLeftSLine />
            </button>
            <button className="next-arrow slider-btn" onClick={() => handleArrowClick('next')}>
              <RiArrowRightSLine />
            </button>
          </div>
          <Link
            className="fs-1 text-white arrow_absolute"
            to="#section02"
            onClick={(e) => handleScroll(e, '#section02')}
          >
            <RiArrowDownDoubleFill />
          </Link>
        </div>       
        ))
      }
      
         
     
    </div>
  );
};

export default BackgroundSlider;