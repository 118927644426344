import React, { useEffect, useState } from "react";
import img1 from "../../assets/testimonial-1.jpeg";
import img2 from "../../assets/testimonial-2.jpeg";
import img3 from "../../assets/testimonial-3.webp";
import img4 from "../../assets/testimonial-4.webp";
import { useLanguage } from "../LanguageContext";
import axios from "axios";
import Cookies from "js-cookie";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

const Testimonial = () => {
  const { apiUrl, apiImg } = useLanguage();
  const [data, setData] = useState("");

  const lan = useSelector((state) => state?.app?.language);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}testimonials`, {
          language: `${lan}`,
        });
        
        const responseData = response.data.msg;
        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [lan]);

  

  return (
    <div className="container testimonial_section text-center p-5">
      <h1>{data?.heading}</h1>
      <p>{data?.sub_heading}</p>

      <div className="testimonial-review">
        {data?.user_list?.map((item) => (
          <div key={item.id} className="testimonial-single">
            <img src={`${apiImg}${item.image}`} alt="Person traveling" />
            <h4>{item?.title}</h4>
            <p>{item?.description}</p>
          </div>
        ))}
        {/* <div className='testimonial-single'>
            <img src={`${apiImg}${data[0]?.image}`} alt="Person traveling"  />
            <h4>{data[0]?.title}</h4>
            <p>{data[0]?.description}</p>
        </div>
        <div className='testimonial-single'>
            <img src={`${apiImg}${data[1]?.image}`} alt="Person traveling"  />
            <h4>{data[1]?.title} </h4>
            <p>{data[1]?.description}</p>
        </div>
        <div className='testimonial-single'>
            <img src={`${apiImg}${data[2]?.image}`} alt="Person traveling"  />
            <h4>{data[2]?.title}</h4>
            <p>{data[2]?.description}</p>
        </div>
        <div className='testimonial-single'>
            <img src={`${apiImg}${data[3]?.image}`} alt="Person traveling"  />
            <h4>{data[3]?.title}</h4>
            <p>{data[3]?.description}</p>
        </div> */}
      </div>
    </div>
  );
};

export default Testimonial;
