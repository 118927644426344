// import React, { createContext, useState, useContext, useEffect } from 'react';
// import Cookies from 'js-cookie';
// import english from '../components/language/english.json';
// import spanish from '../components/language/spanish.json';
// import french from '../components/language/french.json';
// import portuguese from '../components/language/portuguese.json';
// import swedish from '../components/language/swedish.json';
// import hindi from '../components/language/hindi.json';
// import chinese from '../components/language/chinese.json';
// import italian from '../components/language/italian.json';
// import russian from '../components/language/russian.json';
// import arabic from '../components/language/arabic.json';
// import greek from '../components/language/greek.json';
// import japanese from '../components/language/japanese.json';
// import polish from '../components/language/polish.json';
// import german from '../components/language/german.json';
// import korean from '../components/language/korean.json';
// import { useCookies } from 'react-cookie';
// const LanguageContext = createContext();

// export const LanguageProvider = ({ children }) => {
//   const [language, setLanguage] = useState('english');
//   const [translations, setTranslations] = useState(english);
//   const [fontSize, setFontSize] = useState();
//   const [cookies, setCookie] = useCookies(['Lang']);

//   // Function to change language
//   const changeLanguage = (lang) => {
//     setLanguage(lang);
//     let selectedFontSize;
//     let selectedTranslations = {};
//     switch (lang) {
//       case 'english':
//         selectedTranslations = english;
//         break;
//       case 'spanish':
//         selectedTranslations = spanish;
//         break;
//       case 'french':
//         selectedTranslations = french;
//         selectedFontSize = 14;
//         break;
//       case 'portuguese':
//         selectedTranslations = portuguese;
//         break;
//       case 'swedish':
//         selectedTranslations = swedish;
//         break;
//       case 'hindi':
//         selectedTranslations = hindi;
//         break;
//       case 'chinese':
//         selectedTranslations = chinese;
//         break;
//       case 'italian':
//         selectedTranslations = italian;
//         break;
//       case 'russian':
//         selectedTranslations = russian;
//         break;
//       case 'arabic':
//         selectedTranslations = arabic;
//         break;
//       case 'greek':
//         selectedTranslations = greek;
//         break;
//       case 'japanese':
//         selectedTranslations = japanese;
//         break;
//       case 'polish':
//         selectedTranslations = polish;
//         break;
//       case 'german':
//         selectedTranslations = german;
//         break;
//       case 'korean':
//         selectedTranslations = korean;
//         break;
//       default:
//         selectedTranslations = english;
//         break;
//     }
//     setTranslations(selectedTranslations);
//     setCookie('Lang', selectedTranslations);
//     setFontSize(selectedFontSize);
//     Cookies.set('selectedLanguage', lang, { expires: 365 }); // Set cookie for 1 year
//   };

//   // Initialize language from cookie on component mount
//   useEffect(() => {
//     const cookieLanguage = Cookies.get('selectedLanguage');
//     if (cookieLanguage && (cookieLanguage === 'english' || cookieLanguage === 'spanish' || cookieLanguage === 'french' || cookieLanguage === 'portuguese' || cookieLanguage === 'swedish' || cookieLanguage === "hindi" || cookieLanguage === "chinese" || cookieLanguage === "italian" || cookieLanguage === "russian" ||cookieLanguage === "arabic" ||cookieLanguage ==="greek" || cookieLanguage === "japanese" || cookieLanguage === "polish" || cookieLanguage === "german" || cookieLanguage === "korean")) {
//       setLanguage(cookieLanguage);
//       switch (cookieLanguage) {
//         case 'english':
//           setTranslations(english);
//           break;
//         case 'spanish':
//           setTranslations(spanish);
//           break;
//         case 'french':
//           setTranslations(french);
//           break;
//         case 'portuguese':
//           setTranslations(portuguese);
//           break;
//         case 'swedish':
//           setTranslations(swedish);
//           break;
//         case 'hindi':
//           setTranslations(hindi);
//           break;
//         case 'chinese':
//           setTranslations(chinese);
//           break;
//         case 'russian':
//           setTranslations(russian);
//           break;
//         case 'italian':
//           setTranslations(italian);
//           break;
//         case 'arabic':
//           setTranslations(arabic);
//           break;
//         case 'greek':
//           setTranslations(greek);
//           break;
//         case 'japanese':
//           setTranslations(japanese);
//           break;
//         case 'polish':
//           setTranslations(polish);
//           break;
//         case 'german':
//           setTranslations(german);
//           break;
//         case 'korean':
//           setTranslations(korean);
//           break;
//         default:
//           setTranslations(english);
//           break;
//       }
//     }
//   }, []);

//   return (
//     <LanguageContext.Provider value={{ language, translations, changeLanguage ,fontSize}}>
//       {children}
//     </LanguageContext.Provider>
//   );
// };

// export const useLanguage = () => useContext(LanguageContext);

import React, { createContext, useState, useEffect, useContext } from "react";
import { useCookies } from "react-cookie";
import english from "../components/language/english.json";
import spanish from "../components/language/spanish.json";
import french from "../components/language/french.json";
import portuguese from "../components/language/portuguese.json";
import swedish from "../components/language/swedish.json";
import hindi from "../components/language/hindi.json";
import chinese from "../components/language/chinese.json";
import italian from "../components/language/italian.json";
import russian from "../components/language/russian.json";
import arabic from "../components/language/arabic.json";
import greek from "../components/language/greek.json";
import japanese from "../components/language/japanese.json";
import polish from "../components/language/polish.json";
import german from "../components/language/german.json";
import korean from "../components/language/korean.json";
import { useSelector, useDispatch } from 'react-redux';
import {setLanguageData} from '../Redux/Slices/AppSlice'
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("english");
  const [translations, setTranslations] = useState(english);
  const [fontSize, setFontSize] = useState();
  const [cookies, setCookie] = useCookies(["selectedLanguage"]);
  const dispatch=useDispatch();
  const lang=useSelector((state)=>state?.app?.language)
  // Function to change language
  const changeLanguage = (lang) => {
    setLanguage(lang);
    let selectedTranslations = {};
    let selectedFontSize = undefined; // Set default font size
    switch (lang) {
      case "english":
        selectedTranslations = english;
        break;
      case "spanish":
        selectedTranslations = spanish;
        break;
      case "french":
        selectedTranslations = french;
        selectedFontSize = 14; // Example: set specific font size for French
        break;
      case "portuguese":
        selectedTranslations = portuguese;
        break;
      case "swedish":
        selectedTranslations = swedish;
        break;
      case "hindi":
        selectedTranslations = hindi;
        break;
      case "chinese":
        selectedTranslations = chinese;
        break;
      case "italian":
        selectedTranslations = italian;
        break;
      case "russian":
        selectedTranslations = russian;
        break;
      case "arabic":
        selectedTranslations = arabic;
        break;
      case "greek":
        selectedTranslations = greek;
        break;
      case "japanese":
        selectedTranslations = japanese;
        break;
      case "polish":
        selectedTranslations = polish;
        break;
      case "german":
        selectedTranslations = german;
        break;
      case "korean":
        selectedTranslations = korean;
        break;
      default:
        selectedTranslations = english; // Default to English
        break;
    }
    setTranslations(selectedTranslations);
    setFontSize(selectedFontSize); // Update font size if necessary
    setCookie(
      "selectedLanguage",
      lang,
      { maxAge: 365 * 24 * 60 * 60 },
      {
        path: "/",
      }
    ); // Set cookie maxAge for 1 year
  };

  // Initialize language from cookie on component mount
  useEffect(() => {
    const cookieLanguage = cookies["selectedLanguage"];
    if (cookieLanguage && Object.keys(english).includes(cookieLanguage)) {
      setLanguage(cookieLanguage);
      switch (cookieLanguage) {
        case "english":
          setTranslations(english);
          break;
        case "spanish":
          setTranslations(spanish);
          break;
        case "french":
          setTranslations(french);
          break;
        case "portuguese":
          setTranslations(portuguese);
          break;
        case "swedish":
          setTranslations(swedish);
          break;
        case "hindi":
          setTranslations(hindi);
          break;
        case "chinese":
          setTranslations(chinese);
          break;
        case "russian":
          setTranslations(russian);
          break;
        case "italian":
          setTranslations(italian);
          break;
        case "arabic":
          setTranslations(arabic);
          break;
        case "greek":
          setTranslations(greek);
          break;
        case "japanese":
          setTranslations(japanese);
          break;
        case "polish":
          setTranslations(polish);
          break;
        case "german":
          setTranslations(german);
          break;
        case "korean":
          setTranslations(korean);
          break;
        default:
          setTranslations(english); // Default to English
          break;
      }
    }
  }, [cookies?.selectedLanguage]); // Listen for changes in cookies array

  useEffect(()=>{
    dispatch(setLanguageData(language));
  },[language])

  
const apiUrl = "https://development74.shrinkcom.com/dream-dating-app/API/index.php?action=";
const apiImg = "https://development74.shrinkcom.com/dream-dating-app/API/img/";
const apiContact = "https://development81.shrinkcom.com/smtp_mailsss/";
  return (
    <LanguageContext.Provider
      value={{ language, translations, changeLanguage, fontSize ,apiUrl ,apiImg ,apiContact}}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
