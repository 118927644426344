import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdArrowDropdown, IoMdClose } from "react-icons/io";
// import logo from '../assets/logo-dreams.png';
// import logo2 from '../assets/new_logo2.png';
import logo2 from "../assets/transparent-logo.png";
import { useSelector } from "react-redux";
import { FaHeart } from "react-icons/fa";
import { useLanguage } from "../components/LanguageContext";
import axios from "axios";
import { MdLocalGroceryStore } from "react-icons/md";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const menuRef = useRef(null);
  const searchRef = useRef(null);
  const { apiUrl } = useLanguage();
  const [data, setData] = useState("");

  const lan = useSelector((state) => state?.app?.language);

  const toggleMenu = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  const handleScroll = () => {
    setShowSearch(false);
  };
  const { language, changeLanguage, fontSize } = useLanguage();
  const { translations } = useLanguage();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}navbar_content`, {
          language: `${lan}`,
        });

        const responseData = response.data.msg;
        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [lan]);

  /* 
setTimeout(()=>{
    
var selectElement = document.querySelector('.goog-te-combo');

console.log('selectElement', selectElement)

if (selectElement) {
  
    var firstOption = selectElement.querySelector('option');

  
    if (firstOption) {
        firstOption.textContent = 'Language';
    }
}
},700) */

  return (
    <>
      <nav>
        <div className="align-items-center container-fluid d-flex justify-content-between">
          <NavLink
            onClick={() => {
              handleClose();
              scrollToTop();
            }}
            to="/"
          >
            <img className="company-logo" src={logo2} alt="Company Logo" />

            <p className="logo_desc">Social App</p>
          </NavLink>
          <div>
            <ul id="navbar" className={show ? "#navbar active" : "#navbar"}>
              <div onClick={toggleMenu} className="cross-icon">
                <IoMdClose />
              </div>

              <li className="padding-mobile-nav text-center d-lg-none">
                <img className="company-logo2" src={logo2} alt="Company Logo" />
              </li>
              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
                className="active"
              >
                <NavLink to="/" style={{ fontSize: `${fontSize}px` }}>
                  {data.home}
                </NavLink>
              </li>
              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
                className=""
              >
                <NavLink
                  to="/why-choose-dreams?"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  {data.why_choose_dream}
                </NavLink>
              </li>
              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
                className=""
              >
                <NavLink
                  to="/live-streaming"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  Live Streaming
                </NavLink>
              </li>

              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
                className=""
              >
                <NavLink
                  to="/write-your-dreams"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  {data.write_your_dream}
                </NavLink>
              </li>
              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
                className=""
              >
                <NavLink
                  to="/compatibility-tests"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  {data.compatibility_test}
                </NavLink>
              </li>
              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
                className=""
              >
                <NavLink
                  to="/dreams-socail"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  {data.dreams_social}
                </NavLink>
              </li>
              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
              >
                <NavLink to="/aboutUs" style={{ fontSize: `${fontSize}px` }}>
                  {data.about}
                </NavLink>{" "}
              </li>

              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
              >
                <NavLink
                  to="/testimonials"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  {data.testimonials}
                </NavLink>
              </li>

              <li
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
              >
                <NavLink
                  to="/dreams-blogs"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  Dream's Blogs
                </NavLink>
              </li>
              <li className="hide-cls_nav">
                <NavLink style={{ fontSize: `${fontSize}px` }}>
                  {" "}
                  <div
                    id="google_translate_element"
                    className="translate-focus"
                  >
                    {" "}
                  </div>
                </NavLink>
              </li>

              {/*       <li onClick={() => { handleClose(); scrollToTop() }} ><a href='https://www.paypal.com/donate/?hosted_button_id=ND6WSHM3BW74A' target='_blank' className='donate-btn'>{translations.donate} <span> <FaHeart /></span> </a></li> */}
              <li
                className="pe-0"
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
              >
                <NavLink
                  to="/donate-now"
                  className="donate-btn"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  {data.donate}
                  <span>
                    {" "}
                    <FaHeart />
                  </span>
                </NavLink>
              </li>
              <li
                className="pe-0 store-btn"
                onClick={() => {
                  handleClose();
                  scrollToTop();
                }}
              >
                <a
                  href="https://my-store-f92cbd.creator-spring.com/"
                  className="donate-btn"
                  target="_blank"
                  style={{ fontSize: `${fontSize}px` }}
                >
                  Dream's Store
                  <span className="store-icon">
                    {" "}
                    <MdLocalGroceryStore />
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div id="mobile" onClick={toggleMenu}>
            <span>
              <RxHamburgerMenu />
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
