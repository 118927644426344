import React from "react";
import { Link } from "react-router-dom";
import apple from "../assets/apple-download.png";
import android from "../assets/android-download.png";

const SideDownload = () => {
  return (
    <div>
      <div className="side-download">
        <Link to="#" className="download-links">
          <div className="side-div"> Download Now</div>
        </Link>
        <div className="additional-buttons">
          <ul className="download-list">
            <li className="download-list-single">
              <a
                href="https://apps.apple.com/in/app/dreamsocial-and-live-streaming/id6456945052"
                target="_blank"
              >
                <img className="apple-side" src={apple} alt="Com" />
              </a>
            </li>
            <li className="download-list-single">
              <a
                href="https://play.google.com/store/apps/details?id=com.shrinkcom.dreamsdating"
                target="_blank"
              >
                <img className="android-side" src={android} alt="CompLogo" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideDownload;
