import React, { useEffect, useState } from 'react';
import { useLanguage } from '../LanguageContext';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import axios from 'axios';



const FormRequest = () => {

  const { apiUrl , apiImg , apiContact   } = useLanguage();
  const lan=useSelector((state)=>state?.app?.language);
  const [data, setData] = useState('')
  const [email , setEmail] = useState('');
  const [issue, setIssue] = useState('');
  const [description, setDescription] = useState('');
  const [device, setDevice] = useState('');
  const [file, setFile] = useState([]); 
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);


useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(`${apiUrl}dreams_help_section`, {
            language: `${lan}`
          });
  
          const responseData = response.data.msg;
          setData(responseData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [lan]) 




  

  const handleIssue = (e) => {
  setIssue(e.target.value);
};
const handleDesc = (e) => {
  setDescription(e.target.value);
};
const handleDevice = (e) => {
  setDevice(e.target.value);
};

const handleEmailChange = (e) => {
  const inputValue = e.target.value;
  setEmail(inputValue);


  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(inputValue)) {
      setEmailError('Email is Required');
  } else {
      setEmailError('');
  }
};


const handleSubmit = (e) => {

  e.preventDefault();

  if(!emailError && email && issue){
    setLoading(true); // Show loader
    setEmail('');


    const formData = new FormData();
    formData.append('email', email);
    formData.append('issue', issue);
    formData.append('description', description);
    formData.append('device', device);
    // formData.append('image',fileInput)

    // Append file to FormData (assuming 'file' is the file input element)
    const fileInput = document.getElementById('myfile');
    if (fileInput.files.length > 0) {
      formData.append('image', fileInput.files[0]); 
    }
    

    fetch(`${apiContact}send-mail-dreams.php`,
    {
      method:'POST',
      body : formData,
     
    }).then(response => {
      setLoading(false); //Hide Loader

      if(response.ok){
        return response.json();
      }
      throw new Error ("Network response was not ok");
    }) .then(data=>{
      // handle data if needed 
      Swal.fire({
        text:'Submitted Successfully',
        icon:'success',
        confirmButtonText:'OK',
        confirmButtonColor:'#1e2025'
      }).then((result)=>{
        if(result.isConfirmed){
          window.location.reload();
        }
      });
    }).catch(error => {
      setLoading(false); // Hide Loadder

      console.error('There was a problem with fetch',error);
    });
  }
  else{
    if(!email){
      setEmailError("Email is Required");
    }
  }
};





const { language, changeLanguage } = useLanguage();
const { translations } = useLanguage();


  return (
    <div className='form-request overflow-hidden '>
    <div className='request-text'>

    <h3 className='text-center'>Dream’s Help Submit a request</h3>
    <p className='text-center'>Our team is happy to solve your issue. Fill out the form and we'll be in touch as soon as possible.</p>
    </div>
     <form className='request-form' onSubmit={handleSubmit}>
           <p className='text center mx-auto f35'>Submit a request</p>

          <input type='email' id="email" name="email" className='rounded' value={email} placeholder='Enter Your Email Address' onChange={handleEmailChange}/>



          <input type='text' id='issue' name="issue" className='rounded' value={issue} placeholder='Issue' onChange={handleIssue}/>   


          <input type='text' id='description' name="description" className='rounded' value={description} placeholder='Description' onChange={handleDesc}/>

          <input type='text' id='device' name="device" className='rounded' value={device} placeholder='Device' onChange={handleDevice}/>

          <input type="file" id="myfile" name="myfile" className='rounded file_input'/>
         <button className='request-btn' type='submit'>Submit request</button>
        
        </form>
    </div>
  )
}

export default FormRequest;