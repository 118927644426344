import React, { useEffect, useState } from 'react';
import { useLanguage } from '../LanguageContext';
import { useSelector } from 'react-redux';
import axios from 'axios';


const CommunityGuidelines = () => {
  const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();
  const { apiUrl , apiImg  } = useLanguage();
  const lan=useSelector((state)=>state?.app?.language);
  const [data, setData] = useState('')


useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(`${apiUrl}dreams_safety`, {
            language: `${lan}`
          });
  
          const responseData = response.data.msg;
          setData(responseData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [lan]) 


   
  return (
    <div className='container'>
        <h1 className='container bg-dark-subtle text-center py-5 m-5 mx-auto'>{data.heading}</h1>
        <div className='dream-safe-con'>
      <h3 className='dream-safe-head'>{data.dream_safe_had1}</h3>
      <p>{data.dream_safe_had1_para1}</p>
      
     </div>
        <div className='dream-safe-con'>
      {/* <h3 className='dream-safe-head'>{data.dream_safe_had2}</h3>
      <ul>
        <li>{data.dream_safe_had2_list1}</li>
        <li>{data.dream_safe_had2_list2}</li>
        <li>{data.dream_safe_had2_list3}</li>
        <li>{data.dream_safe_had2_list4}</li>
      </ul> */}
      <div dangerouslySetInnerHTML={{ __html: data.description1 }} style={{}} />
     </div>
        <div className='dream-safe-con'>
    {/*   <h3 className='dream-safe-head'>{data.dream_safe_had3}</h3>
      <p>{data.dream_safe_had3_para1}</p>
      <p>{data.dream_safe_had3_para2}</p>
       
  <h6>{data.dream_safe_had3_head1}</h6>
      <ul>
        <li>{data.dream_safe_had3_list1}</li>
        <li>{data.dream_safe_had3_list2}</li>
        <li>{data.dream_safe_had3_list3}</li>
        
      </ul> */}
      <div dangerouslySetInnerHTML={{ __html: data.description2 }} style={{}} />
     </div>
     <div className='dream-safe-con'>
      <h5>{data.dream_safe_had3_head2}</h5>
      <p>{data.dream_safe_had3_head2_para}</p>
     </div>
     <div className='dream-safe-con'>
      <h3 className='dream-safe-head'>{data.dream_safe_had4}</h3>
      <h6>{data.dream_safe_had4_had1}</h6>
      <p>{data.dream_safe_had4_had1_para}</p>

    <div className='dream-safe-section'>
    {/*   <div className='dream-safe'>
        <p>gerfgdsfg</p>
        <p><a href="https://www.rainn.org/" target='_blank'>www.rainn.org</a> | 800 656 4673</p>
      </div>
      
      <div className='dream-safe'>
        <p>gerfgdsfg</p>
        <p><a href="https://www.ic3.gov/" target='_blank'>www.ic3.gov</a></p>
      </div>
      <div className='dream-safe'>
        <p>gerfgdsfg</p>
        <p><a href="https://988lifeline.org/" target='_blank'>https://988lifeline.org</a> | 988</p>
      </div>
      <div className='dream-safe'>
        <p>{data.dream_safe_had4_list4}</p>
        <p><a href="https://www.thehotline.org/" target='_blank'>https://www.thehotline.org</a> | 1-800-799-SAFE (7233) or 1-800-787-3224</p>
      </div>
      <div className='dream-safe'>
        <p>{data.dream_safe_had4_list5}</p>
        <p><a href="https://victimconnect.org/" target='_blank'>https://victimconnect.org</a> | 1-855-4VICTIM (855-484-2846) </p>
      </div>
      <div className='dream-safe'>
        <p>{data.dream_safe_had4_list6}</p>
        <p>Text 'HOME' to 741741 to reach a volunteer Crisis Counselor</p>
      </div>
      <div className='dream-safe'>
        <p>{data.dream_safe_had4_list7}</p>
        <p>National Human Trafficking Hotline: 1-888-373-7888</p>
      </div> */}
    </div>
     


     </div>

    </div>
  )
}

export default CommunityGuidelines
