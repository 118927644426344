
import React from 'react';
import YouTube from 'react-youtube';


const Video = ({video} ) => {
    const opts = {
        height: 440,
        width: 390,
        playerVars: {
          autoplay: 0, // Auto-play the video
          rel: 0,
        },
      };
    
  return (
    <div className='m-0 ps-0 d-flex justify-content-center align-content-center'>
      <YouTube videoId={video} opts={opts} />
    </div>
  )
}

export default Video
