import React, { useEffect, useState } from "react";
import ImageSection from "./ImageSection";
import axios from "axios";
import AboutUsHome from "./AboutUsHome";
import HowDreamWrok from "./HowDreamWrok";
import { useSelector } from "react-redux";
import { useLanguage } from "../LanguageContext";

const Home = () => {
  const { apiUrl, apiImg } = useLanguage();
  const [data, setData] = useState("");
  const lan = useSelector((state) => state?.app?.language);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${apiUrl}home_content`, {
          language: `${lan}`,
        });

        const responseData = response.data.msg;
        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [lan]);
  const imageArr = data?.slider_content;

  const stepArr = data?.how_it_wroks_steps;


  return (
    <div>
      {imageArr && <ImageSection data={imageArr} />}
      <AboutUsHome data={data} />
      <HowDreamWrok data={data} />
    </div>
  );
};

export default Home;
