import React from "react";
import { useLanguage } from "../LanguageContext";
/* import img_1 from '../../assets/step-1.jpg'
import img_2 from '../../assets/step-2.jpg'
import img_3 from '../../assets/step-3.jpg'
import img_4 from '../../assets/step-4.jpg'
import img_5 from '../../assets/step-5.jpg' */

const HowDreamWrok = ({ data }) => {
  const { apiUrl, apiImg } = useLanguage();
  const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();
  return (
    <div className="how_work_section">
      <h1>{data.how_it_wroks}</h1>
      <p>{data.how_it_wroks_decs}</p>
      <div className=" container step_container">
        {data?.how_it_wroks_steps?.map((item, index) => (
          <div className="single_step_container" key={index}>
            <div className="step_img">
              <img src={`${apiImg}${item.image}`} alt="create account" />
              <p>{index + 1}</p>
            </div>
            <div className="step_text">
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowDreamWrok;
