import React, { useEffect, useState } from 'react'
import { MdOutlineEmail, MdOutlinePhoneInTalk } from 'react-icons/md'
import { RiBuilding4Line } from 'react-icons/ri'
import { Link } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';
import { useSelector } from 'react-redux';
import axios from 'axios';


const PrivacyStatement = () => {
    const { language, changeLanguage } = useLanguage();
    const { translations } = useLanguage();
    const { apiUrl , apiImg  } = useLanguage();
  const lan=useSelector((state)=>state?.app?.language);
  const [data, setData] = useState('')

useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(`${apiUrl}privacy_statement`, {
            language: `${lan}`
          });
  
          const responseData = response.data.msg;
          setData(responseData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [lan]) 




  return (
    <div className='container my-2'>
      <h1 className='container bg-dark-subtle text-center py-5 m-5 mx-auto'>{data.heading}</h1>


     <div className=''>
     {/*  <h3 className='about_content_head'>{data.privacy_head}</h3>
      <p>{data.privacy_para1}</p>
      <p>{data.privacy_para2}</p>
      <p>{data.privacy_para3}</p>
      <p>{data.privacy_para4}</p> */}
      <div dangerouslySetInnerHTML={{ __html: data.description }} style={{}} />
     </div>
     <div className='mt-5 text-center'>
      <h3  className='about_content_head'>{data.contact_head}</h3>
      <div>
   
       
        
 
      </div>

      <div>
                <div className='container my-5 overflow-hidden' >

                    <div className='box-contact' >
                        <div className='width_contact mb-4' >
                            <div className='d-flex gap-3 align-items-center contactus-details' >
                                <div className='contact-icon-ring' >
                                    <RiBuilding4Line className='contact-icon black' />
                                </div>
                                <p className=' contact-detail text-center text-md-start text-black' >204, 602 ATULYA IT Park,<br/> Near Bhawarkua Square <br/> Indore (India).</p>
                            </div>
                        </div>
               
                        <div className='width_contact mb-4'>
                            <div className='d-flex gap-3 align-items-center contactus-details' >
                                <div className='contact-icon-ring' >
                                    <MdOutlineEmail className='contact-icon black' />
                                </div>
                                <p className=' contact-detail '> <Link to='mailto:kontakt@omnitelecom.se' className='text-dark'>shrinkcom.software@gmail.com</Link> </p>
                            </div>
                        </div>
                        <div className='width_contact mb-4' >
                            <div className='d-flex gap-3 align-items-center contactus-details' >
                                <div className='contact-icon-ring' >
                                    <MdOutlinePhoneInTalk className='contact-icon black' />
                                </div>
                                <p className=' contact-detail' >+91-8982525343</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
     </div>
    </div>
  )
}

export default PrivacyStatement
