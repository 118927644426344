import Home from "./components/HomePage/Home";
import AboutUs from "./components/aboutus/AboutUs";
import ContactUs from "./components/contact/ContactUs";
import WhyChoose from "./components/whyChooseUs/Main_Section";
import WriteDreams from "./components/writeDreams/MainSection";
import Testimonial from "./components/testimonials/Testimonial";
import CompatibilityTests from "./components/compatibility_tests/Compatibility_Test";
import SubmitRequest from "./components/footerComponent/SubmitRequest";
import PrivacyStatement from "./components/footerComponent/PrivacyStatement";
import DreamSafety from "./components/footerComponent/DeamSafety";
import Mission from "./components/footerComponent/Mission";
import CookiePolicy from "./components/footerComponent/CookiePolicy";
import Donate from "./components/Donate";
import DreamSocial from "./components/dream_social/DreamScail";
import LiveStreaming from "./components/live_streaming/LiveStreaming";
import DreamBlog from "./components/Blog/Blog";
import Adds from "./components/adds/Adds";
export const route = [
  {
    id: 1,
    name: "Home",
    path: "/",
    component: <Home />,
  },
  {
    id: 2,
    name: "About Us",
    path: "/aboutUs",
    component: <AboutUs />,
  },
  {
    id: 3,
    name: "Contact Us",
    path: "/contactUs",
    component: <ContactUs />,
  },
  {
    id: 4,
    name: "Why Choose Dream’s?",
    path: "/why-choose-dreams?",
    component: <WhyChoose />,
  },
  {
    id: 4,
    name: "Write your dream’s",
    path: "/write-your-dreams",
    component: <WriteDreams />,
  },

  {
    id: 5,
    name: "Testimonials",
    path: "/testimonials",
    component: <Testimonial />,
  },
  {
    id: 6,
    name: "Compatibility Tests",
    path: "/compatibility-tests",
    component: <CompatibilityTests />,
  },
  {
    id: 7,
    name: "Submit a request",
    path: "/submit-request",
    component: <SubmitRequest />,
  },
  {
    id: 8,
    name: "Privacy Statement",
    path: "/privacy-statement",
    component: <PrivacyStatement />,
  },
  {
    id: 9,
    name: "Dream's Safety",
    path: "/dreams-safety",
    component: <DreamSafety />,
  },
  {
    id: 10,
    name: "Mission",
    path: "/mission",
    component: <Mission />,
  },

  {
    id: 11,
    name: "Cookie Policy",
    path: "/cookie-policy",
    component: <CookiePolicy />,
  },

  {
    id: 12,
    name: "Donate Now",
    path: "/donate-now",
    component: <Donate />,
  },
  {
    id: 13,
    name: "Dream's Social",
    path: "/dreams-socail",
    component: <DreamSocial />,
  },
  {
    id: 14,
    name: "Live Streaming",
    path: "/live-streaming",
    component: <LiveStreaming />,
  },
  {
    id: 15,
    name: "Dream’s Blogs",
    path: "/dreams-blogs",
    component: <DreamBlog />,
  },
  {
    id: 16,
    name: "Dreams",
    path: "/ads",
    component: <Adds />,
  },
];
