import React, { useEffect, useState } from 'react'
import { useLanguage } from '../LanguageContext';
import { useSelector } from 'react-redux';
import axios from 'axios';
const CookiePolicy = () => {
  const { language, changeLanguage } = useLanguage();
  const { translations } = useLanguage();
  const { apiUrl , apiImg  } = useLanguage();
  const lan=useSelector((state)=>state?.app?.language);
  const [data, setData] = useState('')


useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(`${apiUrl}cookie_policy`, {
            language: `${lan}`
          });
  
          const responseData = response.data.msg;
          setData(responseData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [lan]) 

  return (
    <div className='container my-2'>
      <h1 className='container bg-dark-subtle text-center py-5 m-5 mx-auto'>{data.heading}</h1>
      <div>
      {/* <h3  className='about_content_head'>{data.heading}</h3> */}
      {/* <p>{data.cookie_policy_para1}</p>
      <p>{data.cookie_policy_para2}</p>
      <p>{data.cookie_policy_para3}</p>
      <p>{data.cookie_policy_para4}</p> */}
      <div dangerouslySetInnerHTML={{ __html: data.description }} style={{}} />
     </div>
   {/*   <div className='mt-4 pb-3'>
        <h4>{data.cookie_policy_head2}</h4>
        <ul>
            <li>{data.cookie_policy_list1}</li>
            <li>{data.cookie_policy_list2}</li>
            <li>{data.cookie_policy_list3}</li>
            <li>{data.cookie_policy_list4}</li>
            <li>{data.cookie_policy_list5}</li>
        </ul>
        <p>{data.cookie_policy_list_para}</p>
     </div> */}
    </div>
  )
}

export default CookiePolicy
