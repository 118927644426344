import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

// Import your reducers here
import appReducer  from '../Slices/AppSlice';

const rootReducer = combineReducers({
  app: appReducer ,
  // Add other reducers here
});

const persistConfig = {
  key: 'root',
  storage,
  // Add any additional configuration options here
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
