import React from "react";
import { FaReddit } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { SiRumble } from "react-icons/si";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const SideIcon = () => {
  return (
    <div className="side-contact">
      <div className="sideIcon">
        <Link
          to="https://www.reddit.com/user/dreamsapp2nite/"
          target="_blank"
          className="social-icon"
        >
          <FaReddit className="icons"/>
        </Link>
        <Link
          to="https://www.facebook.com/dreams.application/"
          target="_blank"
          className="social-icon"
        >
          <FaFacebookF className="icons"/>
        </Link>
        <Link
          to="https://www.instagram.com/dreamsapp2nite/"
          target="_blank"
          className="social-icon"
        >
          <FaInstagram className="icons"/>
        </Link>
        <Link
          to="https://www.youtube.com/channel/UC8oVNajkkCqDkiia5f60hbw"
          target="_blank"
          className="social-icon"
        >
          <FaYoutube className="icons"/>
        </Link>
        <Link
          to="https://www.tiktok.com/@dreamsapp2nite?lang=en"
          target="_blank"
          className="social-icon"
        >
          <FaTiktok className="icons"/>
        </Link>
   {/*      <Link
          to="https://rumble.com/c/c-5987442"
          target="_blank"
          className="social-icon"
        >
          <SiRumble className="icons"/>
        </Link> */}
        <Link
          to="https://twitter.com/i/flow/login?redirect_after_login=%2FDREAMSAPP2NITE"
          target="_blank"
          className="social-icon"
        >
          <FaXTwitter className="icons"/>
        </Link>
      </div>
    </div>
  );
};

export default SideIcon;
