import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { route } from "./routes";
import { LanguageProvider } from "./components/LanguageContext";
import SideDownload from "./components/SideDownload";
import SideIcon from "./components/SideIcon";
import Newsletter from "./components/footerComponent/Newsletter";
import Cookies from "./components/CookieConsent";
import { useEffect } from "react";
import { setLanguageData } from "./Redux/Slices/AppSlice";

function App() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        // includedLanguages: 'en,zh-CN,id,ar,hi', // include this for selected languages
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    try {
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    } catch (error) {
      console.log(error);
    }
  }, []);



  return (

      <LanguageProvider>
        <Router basename="/">
          <Navbar />
          {/* <GoogleTranslate /> */}
          <SideDownload />
          <SideIcon />
          <Routes>
            {route.map((route) => {
              return (
                <Route
                  path={route.path}
                  key={route.id}
                  element={route.component}
                />
              );
            })}
          </Routes>
          <Newsletter />
          <Cookies />
          <Footer />
        </Router>
      </LanguageProvider>
    
  );
}

export default App;
